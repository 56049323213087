.layout-error-screen {
  background-color: var(--color-secondary);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.back-to-login-button {
  border-radius: 6px;
}

.error-screen-icon {
  height: 60px;
  width: 60px;
}

.logo-error-screen-icon {
  height: 28px;
  width: 135px;
  position: absolute;
  top: 20px;
  left: 30px;
}

.error-screen-text-container {
  margin-top: 12px;
  margin-bottom: 32px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-screen-title {
  font-size: 20px;
  font-weight: var(--font-weight-medium);
  font-family: var(--font-secondary);
  color: var(--color-white);
}

.error-screen-description {
  font-size: 14px;
  font-weight: var(--font-weight-normal);
  font-family: var(--font-secondary);
  color: var(--color-white);
}
